/* You can add global styles to this file, and also import other style files */
/*   cookies css   */
.ot-sdk-show-settings {
    right: 0;
    z-index: 1;
    position: fixed;
    bottom: 0;
}
#ot-sdk-btn.ot-sdk-show-settings,
#ot-sdk-btn.optanon-show-settings {
    color: #141413 !important;
    border: 1px solid #141413 !important;
}
#ot-sdk-btn.ot-sdk-show-settings:hover,
#ot-sdk-btn.optanon-show-settings:hover {
    color: #fff !important;
    background-color: #cf4500 !important;
}
.dxp-error-custom {
    color: #d7373c;
    font-size: .75rem;
    line-height: .94rem;
    word-break: break-word
}
.portalbg {
    background-color: #141413;
}